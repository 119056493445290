export default function UnitAntrian() {
    return {
        id: null,
        displayName1: null,
        displayName2: null,
        averageWaitTimeMinute: null,
        tujuanRujukan: null,
        jadwal: null,
        displayInKiosk: false,
        queueBefore: null,
        isDeleted: null,
        textToSpeech: null,
        queueStatusCounts: {}
    };
}
