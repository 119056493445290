<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Rincian Unit Antrian</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="clearData()"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
            <div class="kt-list">
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">Id:</span>
                <span class="kt-list__text col-md-7">{{ queueUnit.id }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">Display Name 1:</span>
                <span class="kt-list__text col-md-7">{{ queueUnit.displayName1 }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">Display Name 2:</span>
                <span class="kt-list__text col-md-7">{{ queueUnit.displayName2 }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">Waktu Rata-Rata:</span>
                <span class="kt-list__text col-md-7">{{ queueUnit.averageWaitTimeMinute }} <span v-if="queueUnit.averageWaitTimeMinute">menit</span></span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">Poli:</span>
                <span class="kt-list__text col-md-7">{{ tujuanRujukan }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">Jadwal:</span>
                <span class="kt-list__text col-md-7">{{ jadwal }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">Tampilkan di Kiosk:</span>
                <span class="kt-list__text col-md-7">{{ queueUnit.displayInKiosk | booleanTranslate }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">Text to Speech:</span>
                <span class="kt-list__text col-md-7">{{ queueUnit.textToSpeech }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnitAntrian from "../../../model/unit-antrian-model";
import DayNameEnum from "../../../constants/day-name-enum";

export default {
  data() {
    return {
      queueUnit: new UnitAntrian(),
      tujuanRujukan: null,
      jadwal: null,
      dayName: DayNameEnum
    };
  },
  props: {
    modalName: { type: String },
    tableName: { type: String },
    modalData: { type: Object }
  },
  filters: {
    booleanTranslate: function(data) {
      if(data) {
        return "Ya";
      }
      else {
        return "Tidak";
      }
    }
  },
  methods: {
    setDetail: function (data) {
      this.queueUnit = data;
      if (this.queueUnit.tujuanRujukan) {
        this.tujuanRujukan = data.tujuanRujukan.nama;
      }
      if (this.queueUnit.jadwal) {
        this.jadwal = _.find(this.dayName, function (value, key) {
          if (key.toString() == data.jadwal.hari) {
            return value;
          }
        }) + ', ' + data.jadwal.jamMulai + ' - ' + data.jadwal.jamSelesai;
      }
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.setDetail(this.modalData);
    }
  }
};
</script>
